import React, { useState } from "react";
import "./Calendarregistration.css";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@mui/icons-material/Send";

import ModalRegistrationPartyForm from "../../components/Modal/ModalRegistrationPartyForm/ModalRegistrationPartyForm";
import ModalInfoParty from "../../components/Modal/ModalInfoParty/ModalInfoParty";

export default function Calendarregistration({
  ImgURL,
  Title,
  TypeFormLink,
  SmallDescription,
  LongDescription,
}) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openInfo, setopenInfo] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseInfo = () => {
    setopenInfo(false);
  };

  function handleClick() {
    setOpen(true);
  }
  return (
    <>
      <div>
        <Card sx={{ maxWidth: 345 }}>
          <CardMedia sx={{ height: 345 }} image={ImgURL} title="green iguana" />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {Title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {SmallDescription}
            </Typography>
          </CardContent>
          <CardActions>
            <Box sx={{ "& > button": { m: 1 } }}>
              <LoadingButton
                size="small"
                onClick={handleClick}
                loading={loading}
                loadingIndicator="Waiting"
                variant="outlined"
              >
                register
              </LoadingButton>
            </Box>

            <Button onClick={() => setopenInfo(true)} size="small">
              Learn More
            </Button>
          </CardActions>
        </Card>
        <ModalRegistrationPartyForm
          open={open}
          handleClose={handleClose}
          setLoading={setLoading}
          TypeFormLink={TypeFormLink}
        />
        <ModalInfoParty
          open={openInfo}
          handleClose={handleCloseInfo}
          LongDescriptionDesign={LongDescription}
          ImgURL={ImgURL}
        />
      </div>
    </>
  );
}
