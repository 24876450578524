import React, { useState } from "react";
import "./DesignMoreInfoParty.css";

export default function DesignMoreInfoParty({
  ImgURL,
  A,
  B,
  C,
  D,
  E,
  F,
  G,
  H,
  I,
  J,
  K,
  L,
  M,
  N,
  O,
  P,
  Q,
  R,
}) {
  return (
    <div className="Contenertext">
      <img className="imgSize" src={ImgURL}></img>
      <div>
        <br />
        {A}
      </div>
      <div>
        <br />
        {B}
      </div>
      <div>
        <br />
        {C}
      </div>
      <div>
        <br />
        {D}
      </div>
      {E != "" && (
        <div>
          <br />
          {E}
        </div>
      )}
      {F != "" && (
        <div>
          <br />
          {F}
        </div>
      )}
      {G != "" && (
        <div>
          <br />
          {G}
        </div>
      )}
      {H != "" && (
        <div>
          <br />
          {H}
        </div>
      )}
      {I != "" && (
        <div>
          <br />
          {I}
        </div>
      )}
      {J != "" && (
        <div>
          <br />
          {J}
        </div>
      )}
      {K != "" && (
        <div>
          <br />
          {K}
        </div>
      )}
      {L != "" && (
        <div>
          <br />
          {L}
        </div>
      )}
      {M != "" && (
        <div>
          <br />
          {M}
        </div>
      )}
      {N != "" && (
        <div>
          <br />
          {N}
        </div>
      )}
      {O != "" && (
        <div>
          <br />
          {O}
        </div>
      )}
      {P != "" && (
        <div>
          <br />
          {P}
        </div>
      )}
      {Q != "" && (
        <div>
          <br />
          {Q}
        </div>
      )}
      {R != "" && (
        <div>
          <br />
          {R}
        </div>
      )}
    </div>
  );
}
