import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import "./ModalRegistrationPartyForm.css";

export default function ModalRegistrationPartyForm({
  open,
  handleClose,
  setLoading,
  TypeFormLink,
}) {
  const [infoForm, setinfoForm] = useState({});
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "90%", // Ajustez cette valeur selon vos besoins
          maxHeight: "90vh", // Ajustez cette valeur selon vos besoins
          overflowY: "auto",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        {/* <div style={{ fontSize: "35px" }}>Ajouter une Marque</div>
        <Typography
          id="modal-modal-title-AddProduct"
          variant="h6"
          component="h2"
        >
          <TextField
            label="Partner Name"
            variant="outlined"
            value={
              infoForm.hasOwnProperty("PartnerName") ? infoForm.PartnerName : ""
            }
            onChange={(e) =>
              setinfoForm((prev) => ({ ...prev, PartnerName: e.target.value }))
            }
          />
        </Typography>*/}
        <iframe className="form" src={TypeFormLink}></iframe>
        {/*<Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              handleClose();
              setLoading(true);
            }}
          >
            {/*<a
              href="https://buy.stripe.com/3cs7udgfG52S1eE8wz"
              target="_blank"
              rel="noopener noreferrer"
            >
              register
        </a>
            register
          </Button>
        </Typography>*/}
      </Box>
    </Modal>
  );
}
