import {
  doc,
  updateDoc,
  getDoc,
  setDoc,
  getFirestore,
  query,
  orderBy,
  limit,
  collection,
  getDocs,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { firestore, storage, app } from "../firebaseConfig";

export async function CheckIfValidAkashaPass(AkashaPass) {
  const AkashaPassDocRef = doc(firestore, "Akasha", "AkashaPass");
  const AkashaPassSnapshot = await getDoc(AkashaPassDocRef);
  const allAkashaPass = AkashaPassSnapshot.data().AkashaPass;

  let IsAkashaPassExist = false;
  const SelectPass = allAkashaPass.filter((pass) => pass.pass === AkashaPass);
  if (SelectPass.length != 0 && SelectPass[0].validity === true) {
    IsAkashaPassExist = true;
  } else {
    IsAkashaPassExist = false;
  }
  return IsAkashaPassExist;
}

export async function HandleCreateAcountWithAkashaPass(AkashaPass) {
  const AkashaPassDocRef = doc(firestore, "Akasha", "AkashaPass");
  const AkashaPassSnapshot = await getDoc(AkashaPassDocRef);
  const allAkashaPass = AkashaPassSnapshot.data().AkashaPass;

  let IsAkashaPassExist = false;
  const SelectPass = allAkashaPass.filter((pass) => pass.pass === AkashaPass);
  if (SelectPass.length != 0 && SelectPass[0].validity === true) {
    IsAkashaPassExist = true;
  } else {
    IsAkashaPassExist = false;
  }

  if (IsAkashaPassExist) {
    const UpdateAkashaPass = allAkashaPass.map((pass) => {
      return pass.pass === AkashaPass
        ? { pass: pass.pass, validity: false }
        : pass;
    });
    await setDoc(AkashaPassDocRef, { AkashaPass: UpdateAkashaPass });
  }

  return IsAkashaPassExist;
}

export async function SetAkashaPassFunction() {
  const AkashaPassDocRef = doc(firestore, "Akasha", "AkashaPass");
  const Akashapass = [
    "Ihavedesire4#502340",
    "Ihavedesire4#184876",
    "Ihavedesire4#195207",
    "Ihavedesire4#167890",
    "Ihavedesire4#279520",
    "Ihavedesire4#328714",
    "Ihavedesire4#324289",
    "Ihavedesire4#066402",
    "Ihavedesire4#737166",
    "Ihavedesire4#133874",
    "Ihavedesire4#410000",
    "Ihavedesire4#228372",
    "Ihavedesire4#970424",
    "Ihavedesire4#485262",
    "Ihavedesire4#278410",
    "Ihavedesire4#947468",
    "Ihavedesire4#169983",
    "Ihavedesire4#559154",
    "Ihavedesire4#630090",
    "Ihavedesire4#344987",
    "Ihavedesire4#132956",
    "Ihavedesire4#601732",
    "Ihavedesire4#102871",
    "Ihavedesire4#853591",
    "Ihavedesire4#289710",
    "Ihavedesire4#181100",
    "Ihavedesire4#998770",
    "Ihavedesire4#800046",
    "Ihavedesire4#787086",
    "Ihavedesire4#132301",
    "Ihavedesire4#948477",
    "Ihavedesire4#706265",
    "Ihavedesire4#763329",
    "Ihavedesire4#979219",
    "Ihavedesire4#569900",
    "Ihavedesire4#777807",
    "Ihavedesire4#594883",
    "Ihavedesire4#728482",
    "Ihavedesire4#931280",
    "Ihavedesire4#284872",
    "Ihavedesire4#177554",
    "Ihavedesire4#311291",
    "Ihavedesire4#372855",
    "Ihavedesire4#365718",
    "Ihavedesire4#466542",
    "Ihavedesire4#371664",
    "Ihavedesire4#416962",
    "Ihavedesire4#339288",
    "Ihavedesire4#971173",
    "Ihavedesire4#107345",
    "Ihavedesire4#326085",
    "Ihavedesire4#250154",
    "Ihavedesire4#135980",
    "Ihavedesire4#706710",
    "Ihavedesire4#209979",
    "Ihavedesire4#339594",
    "Ihavedesire4#840510",
    "Ihavedesire4#278986",
    "Ihavedesire4#574593",
    "Ihavedesire4#443581",
    "Ihavedesire4#637297",
    "Ihavedesire4#017659",
    "Ihavedesire4#693982",
    "Ihavedesire4#341667",
    "Ihavedesire4#106656",
    "Ihavedesire4#273612",
    "Ihavedesire4#981864",
    "Ihavedesire4#363250",
    "Ihavedesire4#575757",
    "Ihavedesire4#268347",
    "Ihavedesire4#111669",
    "Ihavedesire4#339056",
    "Ihavedesire4#645040",
    "Ihavedesire4#052291",
    "Ihavedesire4#644597",
    "Ihavedesire4#541990",
    "Ihavedesire4#379909",
    "Ihavedesire4#103085",
    "Ihavedesire4#103819",
    "Ihavedesire4#701260",
    "Ihavedesire4#311978",
    "Ihavedesire4#505402",
    "Ihavedesire4#129174",
    "Ihavedesire4#771377",
    "Ihavedesire4#479760",
    "Ihavedesire4#187327",
    "Ihavedesire4#345956",
    "Ihavedesire4#790878",
    "Ihavedesire4#456684",
    "Ihavedesire4#881277",
    "Ihavedesire4#477688",
    "Ihavedesire4#141758",
    "Ihavedesire4#809692",
    "Ihavedesire4#977585",
    "Ihavedesire4#673984",
    "Ihavedesire4#247350",
    "Ihavedesire4#733610",
    "Ihavedesire4#258196",
    "Ihavedesire4#367577",
    "Ihavedesire4#214108",
    "Ihavedesire4#497530",
    "Ihavedesire4#917788",
    "Ihavedesire4#954244",
    "Ihavedesire4#589262",
    "Ihavedesire4#461734",
    "Ihavedesire4#814529",
    "Ihavedesire4#796606",
    "Ihavedesire4#161833",
    "Ihavedesire4#209121",
    "Ihavedesire4#486028",
    "Ihavedesire4#141021",
    "Ihavedesire4#973083",
    "Ihavedesire4#240248",
    "Ihavedesire4#090877",
    "Ihavedesire4#879304",
    "Ihavedesire4#320163",
    "Ihavedesire4#724840",
    "Ihavedesire4#114950",
    "Ihavedesire4#963156",
    "Ihavedesire4#562133",
    "Ihavedesire4#393499",
    "Ihavedesire4#103001",
    "Ihavedesire4#356621",
    "Ihavedesire4#796264",
    "Ihavedesire4#399490",
    "Ihavedesire4#362007",
    "Ihavedesire4#400842",
    "Ihavedesire4#843081",
    "Ihavedesire4#914753",
    "Ihavedesire4#587271",
    "Ihavedesire4#240056",
    "Ihavedesire4#412113",
    "Ihavedesire4#123133",
    "Ihavedesire4#105419",
  ];

  await setDoc(AkashaPassDocRef, { AkashaPass: Akashapass });
}

export async function SetAkashaPassFunctionUsageUnique() {
  const AkashaPassDocRef = doc(firestore, "Akasha", "AkashaPass");
  const AkashaPassSnapshot = await getDoc(AkashaPassDocRef);
  const allAkashaPass = AkashaPassSnapshot.data().AkashaPass;

  const newAllAkashaPass = allAkashaPass.map((pass) => ({
    pass,
    validity: true,
  }));
  await setDoc(AkashaPassDocRef, { AkashaPass: newAllAkashaPass });
}

export async function AddNewAkashaPAss() {
  const AkashaPassDocRef = doc(firestore, "Akasha", "AkashaPass");
  const AkashaPassSnapshot = await getDoc(AkashaPassDocRef);
  const allAkashaPass = AkashaPassSnapshot.data().AkashaPass;

  const newPass = [
    "Ihavedesire4#649982",
    "Ihavedesire4#855688",
    "Ihavedesire4#606401",
    "Ihavedesire4#640212",
    "Ihavedesire4#497431",
    "Ihavedesire4#857187",
    "Ihavedesire4#810433",
    "Ihavedesire4#583706",
  ];
  const newPassvalid = newPass.map((pass) => ({ pass, validity: true }));
  const newAllList = [...allAkashaPass, ...newPassvalid];

  await setDoc(AkashaPassDocRef, { AkashaPass: newAllList });
}
