import React, { useState } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebaseConfig"; // Assurez-vous d'importer votre instance d'authentification Firebase
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { HandleCreateAcountWithAkashaPass } from "../../Logic/HandleClickFunctions";

const CreateUser = () => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [AkashaPass, setAkashaPass] = useState("");
  const [CheckAkashaPass, setCheckAkashaPass] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSignUp = async () => {
    try {
      const AkashaPassExest = await HandleCreateAcountWithAkashaPass(
        AkashaPass
      );
      if (AkashaPassExest) {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;
        console.log("Nouvel utilisateur créé:", user.uid);
        setCheckAkashaPass("");
        setOpen(false);
      } else {
        setCheckAkashaPass("Akasha Pass not available");
      }
    } catch (error) {
      setError(error.message);
      console.error("Erreur lors de la création de l'utilisateur:", error);
    }
  };

  return (
    <div>
      <Button onClick={handleOpen}>
        <span className="Ln">Sign Up</span>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Sign Up
          </Typography>
          <form onSubmit={handleSignUp}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="Akasha Pass"
              label="Akasha Pass"
              type="Akasha Pass"
              id="Akasha Pass"
              autoComplete="current-password"
              value={AkashaPass}
              onChange={(e) => setAkashaPass(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {error && (
              <Typography variant="body2" color="error">
                {error}
              </Typography>
            )}
            {/*<Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sing Up
            </Button>*/}
            <Button
              onClick={handleSignUp}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            {CheckAkashaPass}
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default CreateUser;
