import React, { useState } from "react";
import "./NoLogInPage.css";
import LogIn from "../Authentification/LogIn/LogIn";
import CreateUser from "../CreateUser/CreateUser";
import SetAkashaPass from "../Button/SetAkashaPass/SetAkashaPass";

export default function NoLogInPage() {
  return (
    <div className="NoLogIn">
      <div></div>
      <div className="contenerLogIn">
        <img
          src="/images/V2LogoAkasha.png"
          alt="AKASHA-SOCIETY"
          border="0"
          style={{ maxWidth: "250px", maxHeight: "250px" }}
        />
        <LogIn />
        <CreateUser />
        {/*<SetAkashaPass />*/}
      </div>
      <div></div>
    </div>
  );
}
//https://i.ibb.co/jrDWXP4/AKASHA-SOCIETY-Logo-Brand-HD-profil-insta.png
