import "./App.css";
import MainDashNSFW from "./components/MainDash/MainDashNSFW/MainDashNSFW";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebaseConfig";
import Registration from "./components/Authentification/Authentification";
import NoLogInPage from "./components/NoLogInPage/NoLogInPage";

function App() {
  const [user, loading, error] = useAuthState(auth);
  const divStyle = {
    backgroundImage: "url(/images/Couv.png)",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height: "300px",
    width: "100%",
    gridColumn: "span 3" /* Span all three columns */,
    gridRow: "span 1",
  };
  return (
    <div className="App">
      {user ? (
        <div className="AppGlass">
          <div style={divStyle}>
            <Registration />
          </div>
          {user ? (
            <>
              <div className="test1"></div>
              <div className="test2">
                <MainDashNSFW />
              </div>
              <div className="test3"></div>

              <div>{/*<StripeContainer />*/}</div>
              {/*<RightSidePartyRegistration />*/}
            </>
          ) : (
            <>
              {/*<LogIn />*/}
              {/*<CreateUser />*/}
            </>
          )}
        </div>
      ) : (
        <NoLogInPage />
      )}
    </div>
  );
}

export default App;
